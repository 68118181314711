.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

#root {
  text-align: center;
  display: flex;
  /* justify-content: center; */
  width: 100vw;
  min-height: 90vh;
  overflow: hidden;
  justify-content: flex-end;
  flex-direction: column;
  /* background: linear-gradient(#fff, #999); */
}

html, body, #root {
  font-family: "Outfit Variable" !important;
  width: 100%;
  height: 100%;
}

#root > div:nth-child(1) {
  height: 100%;
  overflow: auto;
}

#root > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.apptitle {
  font-family: "Raleway";
}
.appsubtitle {
  font-family: "Raleway";
}




* {
  user-select: none;
}

.row {
  flex-direction: row !important;
}

.row > * {
  margin: 5px;
}

h1 {
  color: #fff;
  text-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.3);
}

h2 {
  color: #fff;
}

.swipe {
  position: absolute;
}

.card-container {
  /* max-height: 90vw; */
  max-width: 90vw;
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card {
  position: relative;
  background-color: #fff;
  max-width: 80vw;
  max-height: 70vh;
  height: 60vh;
  border-radius: 6px;
  background-size: cover;
  background-position: center;
}

.card-container,
.card {
  width: 60vh;
}

.card-content {
  width: 100%;
  height: 100%;
}

.card h3 {
  position: absolute;
  bottom: 0;
  margin: 10px;
  color: #fff;
}

.infoText {
  width: 100%;
  justify-content: center;
  display: flex;
  color: #fff;
  animation-name: popup;
  animation-duration: 800ms;
}

.buttons {
  margin: 2px;
  display: flex;
}

.login-panel {
  display: flex;
  flex-direction: column;
}

.nav-footer-link {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

#gallery-container > div {
  height: 100%;
}

#building-detail-container > div {
  height: 100%;
}

.buttons button {
  flex-shrink: 0;
  padding: 10px;
  border: none;
  color: #fff;
  transition: 200ms;
  margin: 10px;
  font-weight: bolder;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
}

.buttons button:hover {
  transform: scale(1.05);
}

progress {
  width: 100%;
  height: 0.2em;
}

progress[value] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  background: orange;
}


.gallery-tile {
  margin: 0px;
}

.gallery-tile > a{
  /* margin: 10px; */
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  overflow: hidden;
}

.gallery-tile > a > img {
  height: 100%;
}

#gallerymap-container {
  height: 100%;

}

/* Reset grayscale for all Leaflet overlay elements */
#gallerymap-container .leaflet-overlay-pane,
#gallerymap-container .leaflet-marker-pane,
#gallerymap-container .leaflet-popup-pane,
.leaflet-marker-icon,
.leaflet-zoom-animated,
.leaflet-interactive
{
  filter: grayscale(0);
}

.arrow {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 4px;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.settings-pane {
  height: 100%;

}

/* Hide scrollbar for Chrome, Safari and Opera */
html::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
html {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* 
@keyframes popup {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(1.1, 1.1);
  }
  30% {
    transform: scale(0.9, 0.9);
  }
  50% {
    transform: scale(1, 1);
  }
  57% {
    transform: scale(1, 1);
  }
  64% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
} */
